<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">题库管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">搜题信息</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items:flex-start">
          <div class="searchbox" style="margin-bottom:15px">
            <div title="搜索状态" class="searchboxItem ci-full">
              <span class="itemLabel">搜索状态:</span>
               <el-select
                v-model="matchState"
                placeholder="请选择搜索状态"
                size="small"
                clearable
              >
                <el-option
                  v-for="item in matchingList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div title="是否有反馈信息" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:8rem">是否有反馈信息:</span>
              <el-select
                v-model="feedbackState"
                placeholder="请选择是否有反馈信息"
                size="small"
                clearable
              >
                <el-option
                  v-for="item in feedbackList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div title="处理状况" class="searchboxItem ci-full">
              <span class="itemLabel">处理状况:</span>
              <el-select
                v-model="disposeState"
                placeholder="请选择处理状况"
                size="small"
                clearable
              >
                <el-option
                  v-for="item in handleList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <el-button
              class="bgc-bv"
              style="margin:0 10px"
              round
              @click="getData()"
              >查询</el-button
            >
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                fixed
              />
              <el-table-column
                label="日期"
                align="left"
                prop="createTime"
                show-overflow-tooltip
                width="150"
              >
              <template slot-scope="scope">
                  {{scope.row.createTime | moment}}
              </template>
              </el-table-column>
              <el-table-column
                label="搜索状态"
                align="left"
                show-overflow-tooltip
                prop="matchState"
                width="140"
              >
              <template slot-scope="scope">
                  {{scope.row.matchState ? '匹配成功':'匹配失败'}}
              </template>
              </el-table-column>
              <el-table-column
                label="用户手机号"
                align="left"
                show-overflow-tooltip
                width="140"
              >
                <template slot-scope="scope">{{
                  scope.row.mobile || "--"
                }}</template>
              </el-table-column>
              <el-table-column
                label="搜索内容"
                align="left"
                prop="questionSearchText"
              >
                <template slot-scope="scope">
                  {{ scope.row.questionSearchText }}
                </template>
              </el-table-column>
              <el-table-column
                label="反馈信息"
                align="left"
                prop="feedback"
              >
                <template slot-scope="scope">
                  {{ scope.row.feedback}}
                </template>
              </el-table-column>
              <el-table-column label="处理状况" align="center" width="120" fixed="right">
                <div slot-scope="scope" class="table-switch">
                  <el-switch
                    :width="20"
                    v-model="scope.row.disposeState"
                    :active-value="true"
                    :inactive-value="false"
                    active-color="#13ce66"
                    @change="
                      (val) => {
                        handleStu(val, scope.row.questionSearchId);
                      }
                    "
                  ></el-switch>
                  <span>{{
                    scope.row.disposeState  ? "已处理" : "未处理"
                  }}</span>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "searchquesstioninformation",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
     matchState:'',
feedbackState:'',
disposeState:'',
      matchingList: [
        {
          value: "",
          label: "全部",
        },
        {
          value: true,
          label: "匹配成功",
        },
        {
          value: false,
          label: "匹配失败",
        },
      ],
      feedbackList: [
        {
          value: "",
          label: "全部",
        },
        {
          value: true,
          label: "有",
        },
        {
          value: false,
          label: "无",
        },
      ],
      handleList: [
        {
          value: "",
          label: "全部",
        },
        {
          value: true,
          label: "已处理",
        },
        {
          value: false,
          label: "未处理",
        },
      ],
    };
  },
  created() {
  },
  computed: {
     
  },
  methods: {
    /* 获取列表数据 */
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        matchState:this.matchState,
        feedbackState:this.feedbackState,
        disposeState:this.disposeState
      };
      this.doFetch(
        {
          url: "/search/qb-question-search/pageList",
          params,
          pageNum,
        },
        true,
        2
      );
      this.getNum(params)
    },
    getNum(params) {
      this.$post('/question/bank/sellNumTotalSum',params,3000,true,2).then(ret => {
          this.sumTotal = ret.data
      }).catch(err => {
        return;
      })
    },
    /**
     * 修改上下架
     */
    handleStu(disposeState, questionSearchId) {
      this.$post(
        "/search/qb-question-search/changeDisposeState",
        {
          disposeState,
          questionSearchId,
        },
        3000,
        true,
        2
      ).then((res) => {
        if (res.status == "0") {
          this.$message({
            type: "success",
            message: res.message,
          });
          this.getData(-1);
        }
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 2.55) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 25;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less"></style>
